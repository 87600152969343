<template>
  <div>
 


  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard",
  components: {
   
  },
  metaInfo () { 
      return { title: this.$t("dashboard.meta.title") } 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("dashboard.meta.title") }]);
  },
  data() {
      return {
        
      };
    },
  methods: {
  
 
  }
};
</script>
